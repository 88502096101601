var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("ค่าใช้จ่าย รายจ่ายภาษีรถ")]),_c('v-toolbar',{staticClass:"my-5 pt-3 pb-15",attrs:{"dense":"","flat":"","rounded":"","outlined":""}},[_c('div',{staticStyle:{"width":"200px"}},[_c('DatePicker',{attrs:{"label":"วันที่","value":_vm.filter.start_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) { return (_vm.filter.start_date = val); }}})],1),_c('div',{staticClass:"mx-10",staticStyle:{"width":"200px"}},[_c('DatePicker',{attrs:{"label":"ถึงวันที่","value":_vm.filter.end_date,"hideDetails":"","outlined":"","rounded":"","dense":""},on:{"onChange":function (val) { return (_vm.filter.end_date = val); }}})],1),_c('v-btn',{staticClass:"ml-5 px-10",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.getData()}}},[_vm._v("ค้นหา")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-2 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"sort-by":"col_a","loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function (x) { return x._id; }) .indexOf(item._id) + 1 + _vm.filter.item_per_page * (_vm.filter.page - 1))+" ")]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_type == 1 ? 'เงินสด' : '')+" "+_vm._s(item.payment_type == 2 ? 'โอน' : '')+" ")]}},{key:"item.date_of_issue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date_of_issue))+" ")]}},{key:"item.net_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.net_price,'0,0'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("ลบ")])],1)]],2)],1)]}},{key:"item.car_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.car_count,'0,0'))+" ")]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }